import Cookies from 'js-cookie';
export var consentCookieName = 'gdprconsent';
// make sure to call this before store consentdata to cookie ( Not legal to store jsondata in cookie)
var makeStringForCookie = function (consentData) {
    var json = JSON.stringify(consentData);
    // will remove double quotes from string
    return json.replace(/"([^"]+)":/g, '$1:');
};
// use this to convert cookie string to consent object
var makeConsentDataObjectFromString = function (cookieStringValue) {
    // will add double quotes back so JSON.parse will work
    return JSON.parse(cookieStringValue.replace(/(\{|,)\s*(.+?)\s*:/g, '$1 "$2":'));
};
export var isConsentGiven = function () {
    return Cookies.get(consentCookieName) ? true : false;
};
export var hasMarketingConsent = function () {
    // Check if we have a consent cookie
    var consentCookie = Cookies.get(consentCookieName);
    if (consentCookie) {
        var consentCookieJson = makeConsentDataObjectFromString(consentCookie);
        // contains true if user has given consent, false if not.
        return consentCookieJson.marketing;
    }
    // Consent is enabled but user has not given reply (we can not show marketing content)
    return false;
};
// Check if skagenWeb is in simple mode
export var isSimpleMode = function () {
    return window.location.search.includes('Simple');
};
// Check if skagenWeb is in app mode (used for app)
export var isAppMode = function () {
    return window.location.search.includes('app=true');
};
export var getConsent = function () {
    if (isSimpleMode() || isAppMode()) {
        return {
            basic: true,
            useful: true,
            analytics: true,
            marketing: true,
        };
    }
    var consentCookie = Cookies.get(consentCookieName);
    if (consentCookie) {
        return makeConsentDataObjectFromString(consentCookie);
    }
    // We have no reply on consent ( Default consent is everything off)
    return {
        basic: true,
        useful: false,
        analytics: false,
        marketing: false,
    };
};
export var saveConsent = function (consentData) {
    var domain = getCurrentCookieDomain(window.location.hostname);
    var consentDataAsString = makeStringForCookie(consentData);
    Cookies.set(consentCookieName, consentDataAsString, {
        expires: 365,
        domain: domain,
        sameSite: 'Lax',
    });
    return true;
};
export var getCurrentCookieDomain = function (hostname) {
    var hostNameAsArray = hostname.split('.');
    var hostlength = hostNameAsArray.length;
    // Special case for domains that does not start with www' or 'test-www'. Like skagenfondene.no and localhost
    if (hostlength > 1 &&
        hostNameAsArray[0] !== 'www' &&
        hostNameAsArray[0] !== 'test-www' &&
        hostNameAsArray[0] !== 'investor' &&
        hostNameAsArray[0] !== 'test-investor') {
        return '.' + hostname;
    }
    // Special case for domains with length 4 like www.skagenfunds.co.uk
    if (hostlength === 4) {
        return ('.' +
            hostNameAsArray[1] +
            '.' +
            hostNameAsArray[2] +
            '.' +
            hostNameAsArray[3]);
    }
    // For domains like www.skagenfondene.no or skagenfondene.no with lengt between 2 and 3
    if (hostlength === 2 || hostlength === 3) {
        return ('.' +
            hostNameAsArray[hostlength - 2] +
            '.' +
            hostNameAsArray[hostlength - 1]);
    }
    // For localhost
    return hostname;
};
