export default {
    name: 'GeneralConsent',
    props: {
        blockViewMode: { type: Boolean },
        consentTexts: { type: Object },
    },
    methods: {
        showDetails: function () {
            this.$emit('consent:showDetails');
        },
        acceptAll: function () {
            this.$emit('consent:acceptAll');
        },
    },
};
