import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
    key: 0,
    class: "skagen-consent"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_GeneralConsent = _resolveComponent("GeneralConsent");
    var _component_DetailsConsent = _resolveComponent("DetailsConsent");
    return ($data.showConsent)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            ($data.showGeneralConsent)
                ? (_openBlock(), _createBlock(_component_GeneralConsent, {
                    key: 0,
                    blockViewMode: $props.blockViewMode,
                    consentTexts: $data.consentTexts,
                    "onConsent:showDetails": $options.showDetails,
                    "onConsent:acceptAll": $options.acceptAll
                }, null, 8 /* PROPS */, ["blockViewMode", "consentTexts", "onConsent:showDetails", "onConsent:acceptAll"]))
                : _createCommentVNode("v-if", true),
            ($data.showDetailConsent)
                ? (_openBlock(), _createBlock(_component_DetailsConsent, {
                    key: 1,
                    blockViewMode: $props.blockViewMode,
                    consentTexts: $data.consentTexts,
                    consentData: $data.consentData,
                    "onConsent:showGeneral": $options.showGeneral,
                    "onConsent:acceptCustom": _cache[0] || (_cache[0] = function (input) { return $options.acceptCustom(input); })
                }, null, 8 /* PROPS */, ["blockViewMode", "consentTexts", "consentData", "onConsent:showGeneral"]))
                : _createCommentVNode("v-if", true)
        ]))
        : _createCommentVNode("v-if", true);
}
