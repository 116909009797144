import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = { class: "page-header" };
var _hoisted_2 = { class: "skagen-purpose-holder" };
var _hoisted_3 = { class: "row" };
var _hoisted_4 = { class: "col-sm-8 col-xs-12" };
var _hoisted_5 = { class: "checkbox checkbox-approved-by" };
var _hoisted_6 = ["id"];
var _hoisted_7 = ["for"];
var _hoisted_8 = { class: "skagen-header" };
var _hoisted_9 = { class: "skagen-text" };
var _hoisted_10 = { class: "skagen-purpose-holder" };
var _hoisted_11 = { class: "row" };
var _hoisted_12 = { class: "col-sm-8 col-xs-12" };
var _hoisted_13 = { class: "checkbox checkbox-approved-by" };
var _hoisted_14 = ["id"];
var _hoisted_15 = ["for"];
var _hoisted_16 = { class: "skagen-header" };
var _hoisted_17 = { class: "skagen-text" };
var _hoisted_18 = { class: "skagen-purpose-holder" };
var _hoisted_19 = { class: "row" };
var _hoisted_20 = { class: "col-sm-8 col-xs-12" };
var _hoisted_21 = { class: "checkbox checkbox-approved-by" };
var _hoisted_22 = ["id"];
var _hoisted_23 = ["for"];
var _hoisted_24 = { class: "skagen-header" };
var _hoisted_25 = { class: "skagen-text" };
var _hoisted_26 = { class: "skagen-purpose-holder" };
var _hoisted_27 = { class: "row" };
var _hoisted_28 = { class: "col-sm-8 col-xs-12" };
var _hoisted_29 = { class: "checkbox checkbox-approved-by" };
var _hoisted_30 = ["id"];
var _hoisted_31 = ["for"];
var _hoisted_32 = { class: "skagen-header" };
var _hoisted_33 = { class: "skagen-text" };
var _hoisted_34 = { class: "skagen-purpose-buttons" };
var _hoisted_35 = ["disabled"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["skagen-consent-details", { 'skagen-consent-overlay': !$props.blockViewMode }])
    }, [
        _createElementVNode("div", {
            class: _normalizeClass(["container skagen-consent-container", { 'container--primary': $props.blockViewMode }])
        }, [
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("h2", null, _toDisplayString($props.consentTexts.heading), 1 /* TEXT */)
            ]),
            _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                            _withDirectives(_createElementVNode("input", {
                                id: $options.checkboxUsefulId,
                                type: "checkbox",
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return (($data.consent.useful) = $event); }),
                                onClick: _cache[1] || (_cache[1] = function ($event) { return ($data.saveDisabled = false); })
                            }, null, 8 /* PROPS */, _hoisted_6), [
                                [_vModelCheckbox, $data.consent.useful]
                            ]),
                            _createElementVNode("label", { for: $options.checkboxUsefulId }, [
                                _createElementVNode("div", _hoisted_8, _toDisplayString($props.consentTexts.headingUseful), 1 /* TEXT */),
                                _createElementVNode("div", _hoisted_9, _toDisplayString($props.consentTexts.textUseful), 1 /* TEXT */)
                            ], 8 /* PROPS */, _hoisted_7)
                        ])
                    ])
                ])
            ]),
            _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("div", _hoisted_13, [
                            _withDirectives(_createElementVNode("input", {
                                id: $options.checkboxAnalyticsId,
                                type: "checkbox",
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) { return (($data.consent.analytics) = $event); }),
                                onClick: _cache[3] || (_cache[3] = function ($event) { return ($data.saveDisabled = false); })
                            }, null, 8 /* PROPS */, _hoisted_14), [
                                [_vModelCheckbox, $data.consent.analytics]
                            ]),
                            _createElementVNode("label", { for: $options.checkboxAnalyticsId }, [
                                _createElementVNode("div", _hoisted_16, _toDisplayString($props.consentTexts.headingAnalytics), 1 /* TEXT */),
                                _createElementVNode("div", _hoisted_17, _toDisplayString($props.consentTexts.textAnalytics), 1 /* TEXT */)
                            ], 8 /* PROPS */, _hoisted_15)
                        ])
                    ])
                ])
            ]),
            _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("div", _hoisted_21, [
                            _withDirectives(_createElementVNode("input", {
                                id: $options.checkboxMarketingId,
                                type: "checkbox",
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) { return (($data.consent.marketing) = $event); }),
                                onClick: _cache[5] || (_cache[5] = function ($event) { return ($data.saveDisabled = false); })
                            }, null, 8 /* PROPS */, _hoisted_22), [
                                [_vModelCheckbox, $data.consent.marketing]
                            ]),
                            _createElementVNode("label", { for: $options.checkboxMarketingId }, [
                                _createElementVNode("div", _hoisted_24, _toDisplayString($props.consentTexts.headingMarketing), 1 /* TEXT */),
                                _createElementVNode("div", _hoisted_25, _toDisplayString($props.consentTexts.textMarketing), 1 /* TEXT */)
                            ], 8 /* PROPS */, _hoisted_23)
                        ])
                    ])
                ])
            ]),
            _createElementVNode("div", _hoisted_26, [
                _createElementVNode("div", _hoisted_27, [
                    _createElementVNode("div", _hoisted_28, [
                        _createElementVNode("div", _hoisted_29, [
                            _withDirectives(_createElementVNode("input", {
                                id: $options.checkboxBasicId,
                                type: "checkbox",
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) { return (($data.consent.basic) = $event); }),
                                disabled: "true"
                            }, null, 8 /* PROPS */, _hoisted_30), [
                                [_vModelCheckbox, $data.consent.basic]
                            ]),
                            _createElementVNode("label", { for: $options.checkboxBasicId }, [
                                _createElementVNode("div", _hoisted_32, _toDisplayString($props.consentTexts.headingBasic), 1 /* TEXT */),
                                _createElementVNode("div", _hoisted_33, _toDisplayString($props.consentTexts.textBasic), 1 /* TEXT */)
                            ], 8 /* PROPS */, _hoisted_31)
                        ])
                    ])
                ])
            ]),
            _createElementVNode("div", _hoisted_34, [
                _createElementVNode("button", {
                    class: "btn btn-primary",
                    onClick: _cache[7] || (_cache[7] = function ($event) { return ($options.save()); }),
                    disabled: $data.saveDisabled
                }, _toDisplayString($props.consentTexts.detailButtonSave), 9 /* TEXT, PROPS */, _hoisted_35),
                (!$props.blockViewMode)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "btn btn-link",
                        onClick: _cache[8] || (_cache[8] = function ($event) { return ($options.back()); })
                    }, _toDisplayString($props.consentTexts.detailButtonBack), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true)
            ])
        ], 2 /* CLASS */)
    ], 2 /* CLASS */));
}
