var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import GeneralConsent from './GeneralConsent';
import DetailsConsent from './DetailsConsent';
import { getConsent, saveConsent, isConsentGiven } from './consentUtil';
import { trackTradidionalGaEvent, EventInfoCategory, EventInfoAction, } from '../components/GoogleTagManager';
import { getConsentTexts } from '@/api/getConsentTexts';
export default {
    name: 'ConsentMain',
    components: {
        GeneralConsent: GeneralConsent,
        DetailsConsent: DetailsConsent,
    },
    props: {
        blockViewMode: { type: Boolean },
    },
    data: function () {
        return {
            fetchError: false,
            initComplete: false,
            showConsent: false,
            showGeneralConsent: false,
            showDetailConsent: false,
            consentTexts: {},
            consentData: {
                basic: true,
                useful: false,
                analytics: false,
                marketing: false,
            },
        };
    },
    created: function () {
        this.fetchConsentData();
    },
    methods: {
        setupConsent: function () {
            if (this.fetchError) {
                // If we fail to fetch consent data, we do not want to show the consent.
                return;
            }
            // We do not want to show disclaimer on Simple mode (from CIP and APP)
            var queryString = window.location.search;
            if (queryString && queryString.includes('Simple')) {
                return;
            }
            if (isConsentGiven()) {
                this.consentData = getConsent();
            }
            // page inline block mode
            if (this.blockViewMode) {
                trackTradidionalGaEvent(EventInfoCategory.Consent, EventInfoAction.None, 'DisplayConcentPage');
                this.showGeneralConsent = false;
                this.showDetailConsent = true;
                this.showConsent = true;
            }
            // 1. We use else to prevent both to display at the same page.
            // 2. Displays on all pages: Check if GDPR Consent cookie exists. Will show consent inline if no cookie.
            else if (!isConsentGiven()) {
                trackTradidionalGaEvent(EventInfoCategory.Consent, EventInfoAction.None, 'DisplayConcent');
                this.showGeneralConsent = true;
                this.showConsent = true;
                // Prevent scrolling on the page when consent is visible
                document.querySelector('html').classList.add('noScroll');
            }
            this.initComplete = true;
        },
        fetchConsentData: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a, err_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 2, 3, 4]);
                            _a = this;
                            return [4 /*yield*/, getConsentTexts()];
                        case 1:
                            _a.consentTexts = _b.sent();
                            return [3 /*break*/, 4];
                        case 2:
                            err_1 = _b.sent();
                            this.fetchError = true;
                            return [3 /*break*/, 4];
                        case 3:
                            this.setupConsent();
                            return [7 /*endfinally*/];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        },
        showDetails: function () {
            trackTradidionalGaEvent(EventInfoCategory.Consent, EventInfoAction.Button, 'showDetails');
            this.showGeneralConsent = false;
            this.showDetailConsent = true;
        },
        showGeneral: function () {
            trackTradidionalGaEvent(EventInfoCategory.Consent, EventInfoAction.Button, 'backToGeneralConsent');
            this.showDetailConsent = false;
            this.showGeneralConsent = true;
        },
        acceptAll: function () {
            this.consentData.basic = true;
            this.consentData.useful = true;
            this.consentData.analytics = true;
            this.consentData.marketing = true;
            saveConsent(this.consentData);
            trackTradidionalGaEvent(EventInfoCategory.Consent, EventInfoAction.Button, 'acceptAll');
            this.showConsent = false;
            document.querySelector('html').classList.remove('noScroll');
        },
        acceptCustom: function (customConsent) {
            saveConsent(customConsent);
            trackTradidionalGaEvent(EventInfoCategory.Consent, EventInfoAction.Button, 'acceptCustom');
            if (!this.blockViewMode) {
                this.showConsent = false;
                document.querySelector('html').classList.remove('noScroll');
            }
        },
    },
};
