import { v4 as uuidv4 } from 'uuid';
export default {
    name: 'DetailsConsent',
    props: {
        blockViewMode: { type: Boolean },
        consentTexts: { type: Object },
        consentData: { type: Object },
    },
    data: function () {
        return {
            uuid: '',
            saveDisabled: true,
            consent: {
                basic: true,
                useful: false,
                analytics: false,
                marketing: false,
            },
        };
    },
    created: function () {
        this.uuid = uuidv4();
        this.consent.basic = this.consentData.basic;
        this.consent.useful = this.consentData.useful;
        this.consent.analytics = this.consentData.analytics;
        this.consent.marketing = this.consentData.marketing;
        // We want save button initial enabled in this mode (must be possible to create / save cookie without doing a change)
        if (!this.blockViewMode) {
            this.saveDisabled = false;
        }
    },
    computed: {
        checkboxBasicId: function () {
            return 'consent-checkbox-basic' + this.uuid;
        },
        checkboxUsefulId: function () {
            return 'consent-checkbox-useful' + this.uuid;
        },
        checkboxAnalyticsId: function () {
            return 'consent-checkbox-analytics' + this.uuid;
        },
        checkboxMarketingId: function () {
            return 'consent-checkbox-marketing' + this.uuid;
        },
    },
    methods: {
        back: function () {
            this.$emit('consent:showGeneral');
        },
        save: function () {
            this.$emit('consent:acceptCustom', this.consent);
            this.saveDisabled = true;
        },
    },
};
